import Choices from "choices.js";
import { ApplicationController } from "../application_controller";

const STATUS_CLASSES = {
  "planned": "select-default",
  "awaiting_confirmation": "select-warning",
  "confirmed": "select-success",
  "canceled": "select-danger",
  "sended": "select-info",
  "rejected": "select-danger"
}

const ALL_CLASSES = ["select-default", "select-warning", "select-success", "select-danger", "select-info"]

export default class extends ApplicationController {
  static targets = [
    "choices",
    "choices_deal_food_supplier_search",
    "choices_deal_service_city_search",
    "choices_data_deal_service_city_search",
    "choices_deal_food_service",
    "choices_status",
    "choices_deal_tickets_service_search",
    "choices_deal_close_status",
    "choices_deal_service_visa_supplier_search",
    "choices_deal_service_guides_search",
    "choices_deal_guides_service_search"
  ]


  initialize() {
    // DATA
    if (this.hasChoices_data_deal_service_city_searchTarget) { this.dataServiceCitySearch(); return; }
    // GUIDE FORM
    // deal services guide suppliers // выбор поставщика, обновляем select для списка услуг
    if (this.hasChoices_deal_service_guides_searchTarget) { this.serviceGuidesSearch(); return; }
    // selected guide services
    if (this.hasChoices_deal_guides_service_searchTarget) { this.guidesServiceSearch(); return; }
    // END GUIDE FORM
    // меняем цвет в зависимости от статуса // choices_status
    if (this.hasChoices_statusTarget) { this.choicesStatus(); return; }
    // choices
    if (this.hasChoicesTarget) { this.choices(); return; }
    // DEAL FORMS
    // choices_deal_service_visa_supplier_search
    if (this.hasChoices_deal_service_visa_supplier_searchTarget) { this.serviceVisaSupplierSearch(); return; }
    // choices_deal_service_city_search | выбор города, при выборе значения обновляем select для поставщика и select для списка услуг
    if (this.hasChoices_deal_service_city_searchTarget) { this.serviceCitySearch(); return; }
    // deal services food suppliers | выбор поставщика, обновляем select для списка услуг
    if (this.hasChoices_deal_food_supplier_searchTarget) { this.foodSupplierSearch(); return; }
    // choices_deal_food_service
    if (this.hasChoices_deal_food_serviceTarget) { this.foodService(); return; }
    // choices_deal_close_status | причины закрытия сделки
    if (this.hasChoices_deal_close_statusTarget) { this.closeStatus(); return; }
  }

  //
  // private
  //

  // DATA
  dataServiceCitySearch() {
    const target = this.choices_data_deal_service_city_searchTarget;
    const options = JSON.parse(target.dataset.options || null);
    const selected = JSON.parse(target.dataset.selected || null);

    let reload_services = this.getSupplierServicesData
    let reload_suppliers = this.getSuppliersSearchData
    let choice = new Choices(target, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: true,
      searchFields: ["customProperties.cyr", "customProperties.lat"],
      searchResultLimit: 1,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText")
    });
    // set as input attribute
    target.choices = choice;

    let reload_choices_id = target.getAttribute("data-reload-target-id")

    if (reload_choices_id) {
      choice.passedElement.element.addEventListener(
        "change",
        function (event) {
          const reload_el = document.getElementById(reload_choices_id)
          if (reload_el) {
            // очищаем выбор селект поставщика
            const reload_choices = reload_el.choices
            // reload_choices.clearInput();
            reload_choices.clearStore();
            reload_choices.clearChoices();
            reload_choices.setChoiceByValue(null);
            reload_suppliers(reload_el, null, true);
            reload_choices.showDropdown();
            // очищаем выбор селект услуг
            let service_choices_id = reload_el.getAttribute("data-reload-target-id")
            if (service_choices_id) {
              reload_services(reload_el, null);
            }

          }
        },
        false,
      );
    }

    if (options) target.choices.setChoices(options, "value", "label", false)
    if (selected) target.choices.setChoiceByValue(selected)
    this.addValidateTrigger(target, target.choices)
  }

  // choices

  serviceGuidesSearch() {
    const target = this.choices_deal_service_guides_searchTarget;
    const options = JSON.parse(target.dataset.options || null);
    const selected = JSON.parse(target.dataset.selected || null);

    let reload_guide_services = this.getSupplierGuideServicesData

    let choice = new Choices(target, {
      removeItemButton: false,
      removeItems: false,
      shouldSort: false,
      shouldSortItems: false,
      searchEnabled: true,
      searchFields: ["customProperties.cyr", "customProperties.lat"],
      searchResultLimit: 1,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText"),
      callbackOnCreateTemplates: function (template) {
        return {
          choice: (classNames, data) => {
            let settings = {}, img_tag = "", languages = ""
            try {
              settings = data.customProperties ? data.customProperties : {}
            } catch {
              settings = {}
            }

            if (settings.image_url) img_tag = "<img src='" + settings.image_url + "' class='img-circle img-size-36' ></img>"
            if (settings.languages) languages = settings.languages

            return template(`
              <div class="guide_select ${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable
              }" data-select-text="${this.config.itemSelectText}" data-choice ${data.disabled
                ? 'data-choice-disabled aria-disabled="true"'
                : 'data-choice-selectable'
              } data-id="${data.id}" data-value="${data.value}" ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
              }>

                <div class="media align-items-center">
                  <div class="mr-3">${img_tag}</div>
                  <div class="media-body">
                    <div>${data.label}</div>
                    <span class="text-muted font-size-11">${languages}</span>
                  </div>
                </div>
              </div>
            `);
          }
        }
      }
    });


    target.choices = choice;

    reload_guide_services(target, "", true);

    // choice.passedElement.element.addEventListener(
    //   "search",
    //   function (event) {
    //     reload_guide_services(this, event.detail.value, false);
    //   },
    //   false,
    // );

    let reload_choices_id = target.getAttribute("data-reload-target-id")

    if (reload_choices_id) {
      choice.passedElement.element.addEventListener(
        "change",
        function (event) {
          const reload_el = document.getElementById(reload_choices_id)
          if (reload_el) {
            reload_guide_services(this, event.detail.value);
          }
        },
        false,
      );
    }
    if (options) target.choices.setChoices(options, "value", "label", false)
    if (selected) target.choices.setChoiceByValue(selected)
    this.addValidateTrigger(target, target.choices)
  }

  guidesServiceSearch() {
    let choice = this.choices_deal_guides_service_searchTarget.choices = new Choices(this.choices_deal_guides_service_searchTarget, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: false,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText")
    });

    let btn_id = this.choices_deal_guides_service_searchTarget.getAttribute("data-controll-btn")

    choice.passedElement.element.addEventListener(
      "change",
      function (event) {
        const btn = document.getElementById(btn_id)
        if (btn) {
          if (event.detail.value) {
            btn.classList.remove("disabled")
          } else {
            btn.classList.add("disabled")
          }
        }
      },
      false,
    );
    this.addValidateTrigger(this.choices_deal_guides_service_searchTarget, this.choices_deal_guides_service_searchTarget.choices)
  }

  choicesStatus() {
    const is_disabled = this.choices_statusTarget.dataset.disabled || null;

    let choice = new Choices(this.choices_statusTarget, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: false,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText")
    });
    this.choices_statusTarget.choices = choice
    const parent_target = this.choices_statusTarget.closest("div.select-inline")

    if (is_disabled == 'true') {
      choice.disable();
    }

    choice.passedElement.element.addEventListener(
      "change",
      function (event) {
        if (parent_target) {
          const class_name = STATUS_CLASSES[event.detail.value]
          // console.log(class_name)
          parent_target.classList.remove(...ALL_CLASSES);
          parent_target.classList.add(class_name)
        }
      },
      false,
    );

    this.addValidateTrigger(this.choices_statusTarget, this.choices_statusTarget.choices)
  }

  choices() {
    this.choicesTarget.choices = new Choices(this.choicesTarget, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: false,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText"),
      callbackOnInit: function () {
        if (this.passedElement.element.getAttribute("data-disable") == "true") {
          this.disable();
        }
      }
    });
    this.addValidateTrigger(this.choicesTarget, this.choicesTarget.choices)
  }
  serviceVisaSupplierSearch() {
    this.choices_deal_service_visa_supplier_searchTarget.choices = new Choices(this.choices_deal_service_visa_supplier_searchTarget, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: true,
      searchFields: ["label"],
      // searchResultLimit: 4,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText")
    });
    this.addValidateTrigger(this.choices_deal_service_visa_supplier_searchTarget, this.choices_deal_service_visa_supplier_searchTarget.choices)
  }

  serviceCitySearch() {
    let reload_services = this.getSupplierServicesData
    let reload_suppliers = this.getSuppliersSearchData
    let choice = new Choices(this.choices_deal_service_city_searchTarget, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: true,
      searchFields: ["label"],
      searchResultLimit: 1,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText")
    });
    // set as input attribute
    this.choices_deal_service_city_searchTarget.choices = choice;

    let reload_choices_id = this.choices_deal_service_city_searchTarget.getAttribute("data-reload-target-id")

    if (reload_choices_id) {
      choice.passedElement.element.addEventListener(
        "change",
        function (event) {
          const reload_el = document.getElementById(reload_choices_id)
          if (reload_el) {
            // очищаем выбор селект поставщика
            const reload_choices = reload_el.choices
            // reload_choices.clearInput();
            reload_choices.clearStore();
            reload_choices.clearChoices();
            reload_choices.setChoiceByValue(null);
            reload_suppliers(reload_el, null, true);
            reload_choices.showDropdown();
            // очищаем выбор селект услуг
            let service_choices_id = reload_el.getAttribute("data-reload-target-id")
            if (service_choices_id) {
              reload_services(reload_el, null);
            }

          }
        },
        false,
      );
    }

    this.addValidateTrigger(this.choices_deal_service_city_searchTarget, this.choices_deal_service_city_searchTarget.choices)
  }

  foodSupplierSearch() {
    let reload_services = this.getSupplierServicesData
    let reload_suppliers = this.getSuppliersSearchData
    let choice = new Choices(this.choices_deal_food_supplier_searchTarget, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: true,
      searchChoices: false,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText")
    });
    // set as input attribute

    this.choices_deal_food_supplier_searchTarget.choices = choice;

    reload_suppliers(this.choices_deal_food_supplier_searchTarget, null, true);

    choice.passedElement.element.addEventListener(
      "search",
      function (event) {
        reload_suppliers(this, event.detail.value, false);
      },
      false,
    );

    let reload_choices_id = this.choices_deal_food_supplier_searchTarget.getAttribute("data-reload-target-id")

    if (reload_choices_id) {
      choice.passedElement.element.addEventListener(
        "change",
        function (event) {
          const reload_el = document.getElementById(reload_choices_id)
          if (reload_el) {
            reload_services(this, event.detail.value);
          }
        },
        false,
      );
    }

    this.addValidateTrigger(this.choices_deal_food_supplier_searchTarget, this.choices_deal_food_supplier_searchTarget.choices)
  }

  foodService() {
    let choice = this.choices_deal_food_serviceTarget.choices = new Choices(this.choices_deal_food_serviceTarget, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: false,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText")
    });

    let btn_id = this.choices_deal_food_serviceTarget.getAttribute("data-controll-btn")

    choice.passedElement.element.addEventListener(
      "change",
      function (event) {
        const btn = document.getElementById(btn_id)
        if (btn) {
          if (event.detail.value) {
            btn.classList.remove("disabled")
          } else {
            btn.classList.add("disabled")
          }
        }
      },
      false,
    );
    this.addValidateTrigger(this.choices_deal_food_serviceTarget, this.choices_deal_food_serviceTarget.choices)
  }

  closeStatus() {
    let choice = new Choices(this.choices_deal_close_statusTarget, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: true,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText")
    });
    // set as input attribute
    this.choices_deal_close_statusTarget.choices = choice;

    let failure_reason_choices_id = this.choices_deal_close_statusTarget.getAttribute("data-failure-reason-choices-id");
    let failure_reason_choices_field = document.querySelector(failure_reason_choices_id);
    let failure_reason_status = this.choices_deal_close_statusTarget.getAttribute("data-failure-reason-status");
    let label_text = failure_reason_choices_field.parentElement.querySelector("span.text-danger");

    if (failure_reason_choices_field) {
      choice.passedElement.element.addEventListener(
        "change",
        function (event) {
          let el = choice.passedElement.element;
          let failure_reason_choice = failure_reason_choices_field.choices;

          if (el.value == failure_reason_status) {
            failure_reason_choice.enable();
            label_text.innerText = "*";
          } else {
            failure_reason_choice.disable();
            label_text.innerText = "";
            failure_reason_choice.containerOuter.element.classList.remove("error");
            let error_msg = failure_reason_choice.containerOuter.element.parentElement.querySelector('span.error-message');
            if (error_msg) {
              error_msg.innerText = "";
            }
          }
        },
        false
      );
    }

    this.addValidateTrigger(this.choices_deal_close_statusTarget, this.choices_deal_close_statusTarget.choices)
  }


  //
  // Guide supplier services
  //

  async getSupplierGuideServicesData(target, supplier_id) {
    let reload_field_id = target.getAttribute("data-reload-target-id");
    let services_url = target.getAttribute("data-services-url");
    let reload_field = document.getElementById(reload_field_id);

    if (reload_field.choices) {

      let reload_choice = reload_field.choices;
      reload_choice.clearInput();
      reload_choice.clearStore();
      reload_choice.clearChoices();
      reload_choice.setChoiceByValue(null);

      if (supplier_id) {
        reload_choice.setChoices(async () => {
          try {
            const items = await fetch(services_url + "?supplier_id=" + supplier_id);
            return items.json();
          } catch (err) {
            // console.error(err);
          }
        });
        reload_choice.showDropdown();
      }
      let btn_id = reload_field.getAttribute("data-controll-btn")
      const btn = document.getElementById(btn_id)
      if (btn) btn.classList.add("disabled")
    }
  }

  //
  //
  async getSupplierServicesData(target, supplier_id) {
    // console.log(target)
    let reload_field_id = target.getAttribute("data-reload-target-id");
    let services_url = target.getAttribute("data-services-url");
    let reload_field = document.getElementById(reload_field_id);

    if (reload_field) {

      let reload_choice = reload_field.choices;
      reload_choice.clearInput();
      reload_choice.clearStore();
      reload_choice.clearChoices();
      reload_choice.setChoiceByValue(null);

      if (supplier_id) {
        reload_choice.setChoices(async () => {
          try {
            const items = await fetch(services_url + "?supplier_id=" + supplier_id);
            return items.json();
          } catch (err) {
            // console.error(err);
          }
        });
        reload_choice.showDropdown();
      }
      let btn_id = reload_field.getAttribute("data-controll-btn")
      const btn = document.getElementById(btn_id)
      if (btn) btn.classList.add("disabled")
    }
  }

  async getSuppliersSearchData(target, q) {
    let url = target.getAttribute("data-search-url");
    let city_element = document.getElementById("deal_services_add_new_city_id");
    let service_type = target.getAttribute("data-service-type");
    let choice = target.choices;
    let query = (q == null ? '' : q)
    if (service_type) {
      url += "?service_type=" + service_type + "&q=" + query;
      if(city_element)
        url += "&city_id=" + city_element.value;
      await fetch(url)
        .then(res => res.json())
        .then((data) => {
          choice.setChoices(data, 'value', 'label', true)
        });
    }
  }


  messageText(key) {
    let locale = document.body.getAttribute("data-lang");
    const message_list = {
      "ru": {
        loadingText: "Загрузка...",
        noResultsText: "Ничего не найдено",
        noChoicesText: "Нет вариантов выбора",
        itemSelectText: "Нажмите для выбора"
      },
      "en": {
        loadingText: "Loading...",
        noResultsText: "No results found",
        noChoicesText: "No choices to choose from",
        itemSelectText: "Press to select"
      }
    }
    return message_list[locale][key];
  }

  addValidateTrigger(html_element, choices_object) {
    let form, controller, fieldContainer;
    form = html_element.closest('form')
    if (form != undefined)
      controller = this.formController(form)
    if (controller != undefined)
      fieldContainer = html_element.closest('.form-group');

    if (controller != undefined && fieldContainer != undefined) {
      choices_object.passedElement.element.addEventListener(
        'change',
        function (event) {
          controller.checkChoicesMulti(fieldContainer);
        },
        false,
      );
    }
  }

  formController(form_element) {
    return this.application.getControllerForElementAndIdentifier(form_element, "layout--form")
  }
}
