import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import Choices from "choices.js";
import { ApplicationController } from "../application_controller";

const TABLE_ID = "deal_transports_form_table"

Tabulator.prototype.extendModule("columnCalcs", "calculations", {
  "transportsTotalText": function (values, data, calcParams) {
    const table = document.getElementById("transports_table")
    if (table)
      return table.getAttribute("data-total-text")
  },
  "transportsTotalAmount": function (values, data, calcParams) {
    const table = document.getElementById("transports_table")
    if (!table) return 0;
    let total_html = table.getAttribute("data-total-amount");
    return total_html;
  }
});

export default class extends ApplicationController {
  static targets = ["table",
    "mapping_checkbox",
    "mapping_dropdown",
    "route_stages_link",
    "search",
    "deal_period",
    "service_period",
    "status",
    "deal_status",
    "supplier_ids"
  ]


  connect() {
    // Main table
    if (this.hasTableTarget) {
      const content_url = this.data.get("table-load-url");
      this.createTable(content_url);
    }
  }

  filterTable() {
    this.setFilterToTable(false);
  }

  setFilterToTable(is_mapping) {
    let table = this.tableTarget.tabulator;
    let filters = []

    // let search_data = []
    if (this.hasSearchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.searchTarget.value
      })
    }

    if (this.hasDeal_periodTarget) {
      filters.push({
        field: "deal_period",
        type: "=",
        value: this.deal_periodTarget.value
      })
    }

    if (this.hasService_periodTarget) {
      filters.push({
        field: "service_period",
        type: "=",
        value: this.service_periodTarget.value
      })
    }

    if (this.hasStatusTarget) {
      filters.push({
        field: "status",
        type: "=",
        value: this.statusTarget.choices.getValue(true)
      })
    }

    if (this.hasDeal_statusTarget) {
      filters.push({
        field: "deal_status",
        type: "=",
        value: this.deal_statusTarget.choices.getValue(true)
      })
    }

    if (this.hasSupplierTarget) {
      filters.push({
        field: "supplier_ids",
        type: "=",
        value: this.supplierTarget.choices.getValue(true)
      })
    }

    if (filters.length) {
      table.setFilter(filters);
    }
  }

  mappingOpen(event) {
    if (this.hasMapping_dropdownTarget) {
      const mapping_dropdown = this.mapping_dropdownTarget;
      new BSN.Dropdown(mapping_dropdown).toggle();
    }
  }

  mappingNotCloseClick(event) {
    event.stopPropagation();
  }


  toggleRouteStages(event) {
    this.toggleRouteStagesTable(event.currentTarget)
  }

  toggleRouteStagesTable(div_target, force_open = "false") {
    let tr = div_target.closest('.tabulator-row')
    let icon = div_target.querySelector('i')

    if (tr) {
      if (tr.classList.contains("show-child-table")) {
        tr.classList.remove("show-child-table")
      } else {
        tr.classList.add("show-child-table")
      }

      if (force_open == "true") {
        tr.classList.add("show-child-table")
      }
    }


    if (icon) {
      if (icon.classList.contains("fa-angle-down")) {
        icon.classList.remove("fa-angle-down")
        icon.classList.add("fa-angle-up")
      } else {
        icon.classList.remove("fa-angle-up")
        icon.classList.add("fa-angle-down")
      }
    }
  }


  async createTable(url) {
    const columns = this.data.get("table-columns");
    const table_target = this.tableTarget;

    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");

    let addTransports = this.addTransportsSubTable
    let transports_columns = this.transportsColumns()
    let tabulator_column_wrap = super.tabulatorColumnWrap
    transports_columns = transports_columns[super.appLocale]

    let table_props = {
      virtualDomBuffer: 4000,
      index: "id",
      height: (height - 180) + "px",
      persistenceID: "transports_main_table",
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),

      // sort
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],

      // pagination
      pagination: "remote",
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      paginationSizeSelector: [10, 25, 50],
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",
      //
      ajaxResponse:function(url, params, response){
        const table = document.getElementById("transports_table")
        table.setAttribute("data-total-amount", response.total_amount);

        return response;
      },
      //
      rowFormatter: function (row) {
        const data = row.getData();
        if (data.deal_services__status) {
          row.getElement().classList.add("row-" + data.deal_services__status)
        }

        if (data.deal_services__manager_status) {
          row.getElement().classList.add("row--" + data.deal_services__manager_status + "-bg")
        }

        //
        // add tickets sub table
        //
        addTransports(row, transports_columns);
      }
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }


  addTransportsSubTable(row, transports_columns) {
    const transports_data = row.getData().deal_services__route_stages
    const tableId = "transports_table_" + row.getData().id;
    const childTable = document.getElementById(tableId)

    if (childTable) {
      const childTabulator = childTable.tabulator
      childTabulator.setData(transports_data)
    } else {
      //create and style holder elements
      let holderEl = document.createElement("div");
      holderEl.classList = "child-table transports-table ml-auto mr-20";
      holderEl.style.padding = "0 0 10px 20px";
      let tableEl = document.createElement("div");
      tableEl.classList = "table table-striped";
      tableEl.setAttribute("id", tableId);
      holderEl.appendChild(tableEl);

      row.getElement().appendChild(holderEl);

      if (transports_data) {
        tableEl.tabulator = new Tabulator(tableEl, {
          minHeight: 300,
          layout: "fitDataStretch",
          data: transports_data,
          headerSort: true,
          columns: transports_columns
        })
      }
    }
  }

  transportsColumns() {
    const columns = {
      "ru": [{
        title: "№",
        field: "index",
        formatter: "plaintext",
        headerSort: false,
        width: 50
      },
      {
        title: "Дата/Время",
        field: "date_time",
        headerSort: false,
        width: 150
      },
      {
        title: "Услуга",
        field: "transport_service",
        headerSort: false,
        width: 120
      },
      {
        title: "Город отправления",
        field: "from_city",
        headerSort: false,
        width: 150
      },
      {
        title: "Точка отправления",
        field: "from_service",
        headerSort: false,
        width: 200
      },
      {
        title: "Город назначения",
        field: "to_city",
        headerSort: false,
        width: 150
      },
      {
        title: "Точка назначения",
        field: "to_service",
        headerSort: false,
        width: 200
      },
      {
        title: "Номер рейса",
        field: "flight_number",
        headerSort: false,
        width: 120
      },
      {
        title: "Водитель",
        field: "driver",
        headerSort: false,
        width: 150
      },
      {
        title: "Машина, номер",
        field: "vehicle_type_and_number",
        headerSort: false,
        width: 150
      },
      {
        title: "Отель",
        field: "hotel",
        headerSort: false,
        width: 150,
      }],
      "en": [{
        title: "№",
        field: "index",
        formatter: "plaintext",
        headerSort: false,
        width: 50
      },
      {
        title: "Date/Time",
        field: "date_time",
        headerSort: false,
        width: 150
      },
      {
        title: "Service",
        field: "transport_service",
        headerSort: false,
        width: 120
      },
      {
        title: "Departure city",
        field: "from_city",
        headerSort: false,
        width: 150
      },
      {
        title: "Departure point",
        field: "from_service",
        headerSort: false,
        width: 200
      },
      {
        title: "Destination city",
        field: "to_city",
        headerSort: false,
        width: 150
      },
      {
        title: "Destination point",
        field: "to_service",
        headerSort: false,
        width: 200
      },
      {
        title: "Flight number",
        field: "flight_number",
        headerSort: false,
        width: 120
      },
      {
        title: "Driver",
        field: "driver",
        headerSort: false,
        width: 150
      },
      {
        title: "Vehicle, number",
        field: "vehicle_type_and_number",
        headerSort: false,
        width: 150
      },
      {
        title: "Hotel",
        field: "hotel",
        headerSort: false,
        width: 150,
      }]
    }
    return columns
  }
}